<template>
  <ion-modal
    ref="modal"
    id="mobile-menu-modal"
    :isOpen="isOpen"
    @didDismiss="closeDrawer"
    :enter-animation="enterAnimation"
    :leave-animation="leaveAnimation"
    aria-label="Navigation menu"
  >
    <ion-content>
      <slot></slot>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  createAnimation,
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonAvatar,
  IonImg,
  IonLabel
} from "@ionic/vue";
import MobileNav from "@/components/MobileNav.vue";

export default {
  name: "App",
  components: {
    MobileNav,
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonList,
    IonAvatar,
    IonImg,
    IonLabel
  },
  data() {
    return {};
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    closeDrawer() {
      this.dismiss();
      this.$emit("update:isOpen", false);
    },
    dismiss() {
      this.$refs.modal.$el.dismiss();
    },
    enterAnimation(baseEl) {
      const root = baseEl.shadowRoot;

      const backdropAnimation = createAnimation()
        .addElement(root.querySelector("ion-backdrop"))
        .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

      const wrapperAnimation = createAnimation()
        .addElement(root.querySelector(".modal-wrapper"))
        .keyframes([
          { offset: 0, opacity: "0", transform: "translateX(-100%)" },
          { offset: 1, opacity: "1", transform: "translateX(0)" }
        ]);

      return createAnimation()
        .addElement(baseEl)
        .easing("ease-out")
        .duration(200)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation(baseEl) {
      const root = baseEl.shadowRoot;

      const backdropAnimation = createAnimation()
        .addElement(root.querySelector("ion-backdrop"))
        .fromTo("opacity", "var(--backdrop-opacity)", "0.01");

      const wrapperAnimation = createAnimation()
        .addElement(root.querySelector(".modal-wrapper"))
        .keyframes([
          { offset: 0, opacity: "1", transform: "translateX(0)" },
          { offset: 1, opacity: "0", transform: "translateX(-100%)" }
        ]);

      return createAnimation()
        .addElement(baseEl)
        .easing("ease-in")
        .duration(200)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    }
  }
};
</script>

<style lang="scss" scoped>
ion-modal#mobile-menu-modal {
  --width: fit-content;
  --min-width: 95%;
  --height: 100%;
  justify-content: left;
  --backdrop-opacity: 0.8;
  ion-content {
    &::part(background) {
      background: $brand--sidemenu-bg;
    }
  }
}
</style>
